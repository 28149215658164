import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import ecommerce from "../../../../../assets/img/ecommerce.gif";

export default function ServeursVirtuelsInfogeres() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Solutions pour le cybercommerce : vendez en ligne!</h1>
        <img
          src={ecommerce}
          alt="Femme sur ordinateur portable"
          style={{ float: "right" }}
        ></img>
        <p>
          Les solutions NEBS pour le cybercommerce vous offrent la possibilité
          d'ajouter facilement et rapidement des produits à votre site et de les
          vendre en ligne par l'intermédiaire du panier d'achats virtuel. Elles
          vous permettront de traiter les achats par carte de crédit, de rendre
          votre site multilingue, de calculer de façon automatique les coûts
          d'expédition et les taxes applicables selon la province, et beaucoup
          plus encore.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Construisez votre boutique virtuelle dès aujourd'hui</h2>
            <h3>
              Il est très facile de créer votre boutique virtuelle avec
              EasyStoreMaker Pro
            </h3>
            <ul>
              <li>Ajout commode des produits à votre catalogue en ligne</li>
              <li>Vérificateur des stocks intégré</li>
              <li>
                Traitement des transactions par carte de crédit directement à
                partir de votre site
              </li>
              <li>Intégration harmonieuse avec EasySiteWizard Pro</li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={
                  "/developpez/services-web/solutions-pour-le-cybercommerce/oscommerce/"
                }
                margin={"10px 0"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Dotez votre site d'un panier d'achat évolué</h2>
            <h3>Faites de meilleures affaires en ligne avec osCommerce</h3>
            <ul>
              <li>Modèles de conception professionnelle osCommerce</li>
              <li>Assistant d'installation convivial</li>
              <li>Interface d'administration poussée</li>
              <li>Module d'expédition complet.</li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                margin={"10px 0"}
                minWidth={"200px"}
                url={
                  "/developpez/services-web/solutions-pour-le-cybercommerce/easystoremaker-pro/"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Layout from "../../../../other/layout";
import ConceptionDeSitesWeb from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/SolutionsPourLeCybercommerce/SolutionsPourLeCybercommerce";
import SEO from "../../../../other/seo";

export default function conceptionDeSitesWeb() {
  return (
    <Layout>
      <SEO title="Solutions pour le cybercommerce" />

      <ConceptionDeSitesWeb />
    </Layout>
  );
}
